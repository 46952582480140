.table {
  width: 100%;
  border-collapse: collapse;
  --gap: 1.5em;
}

.thead {
  color: #47679b;
}

.td {
  border-top: 1px solid #ced4de;
  padding: 0.9em 0;
  vertical-align: center;
}

.td + .td {
  border-top: 1px solid #ced4de;
  padding-left: var(--gap);
}

.th {
  font-weight: 500;
}

.th + .th {
  font-weight: 500;
  padding-left: var(--gap);
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
